import request from '@/utils/request'

// 查询学校列表
export function listStudent (query) {
  return request({
    url: '/rongyi/student/list',
    method: 'get',
    params: query
  })
}

// 查询学校详细
export function getStudent (studentId) {
  return request({
    url: '/rongyi/student/' + studentId,
    method: 'get'
  })
}

// 新增学校
export function addStudent (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/student/',
    method: 'post',
    data: data
  })
}

// 修改学校
export function updateStudent (data) {
  return request({
    url: '/rongyi/student/',
    method: 'put',
    data: data
  })
}

// 删除学校
export function delStudent (studentId) {
  return request({
    url: '/rongyi/student/' + studentId,
    method: 'delete'
  })
}
