<template>
    <a-modal
        ref="createModal"
        :title="readOnly ? '详情' : form.studentId ? '学生编辑' : '新增操作'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
        :okButtonProps="{
            props: {
                disabled: readOnly ? true : false,
            },
        }"
    >
        <a-spin :spinning="spining">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="12">
                        <a-form-item label="学校">
                            <a-select :disabled="readOnly" v-model="form.universityId" @change="handleUniversityChange">
                                <a-select-option
                                    v-for="item in universityList"
                                    :value="item.universityId"
                                    :key="item.universityId"
                                    >{{ item.universityName }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="分院">
                            <a-select
                                :disabled="readOnly"
                                v-model="form.departmentId"
                                v-hasPermi="['organization:department:list']"
                                @change="handleDepartmentChange"
                            >
                                <a-select-option
                                    v-for="item in departmentList"
                                    :value="item.departmentId"
                                    :key="item.departmentId"
                                    >{{ item.departmentName }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="年级">
                            <a-select
                                :disabled="readOnly"
                                v-model="form.gradeId"
                                v-hasPermi="['organization:grade:list']"
                                @change="handleGradeChange"
                            >
                                <a-select-option v-for="item in gradeList" :value="item.gradeId" :key="item.gradeId">{{
                                    item.gradeName
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="专业">
                            <a-select
                                :disabled="readOnly"
                                v-model="form.specializeId"
                                v-hasPermi="['organization:specialize:list']"
                                @change="handleSpecializeChange"
                            >
                                <a-select-option
                                    v-for="item in specializeList"
                                    :value="item.specializeId"
                                    :key="item.specializeId"
                                    >{{ item.specializeName }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="班级">
                            <a-select
                                :disabled="readOnly"
                                v-model="form.classId"
                                v-hasPermi="['organization:class:list']"
                                @change="handleClassChange"
                            >
                                <a-select-option v-for="item in classList" :value="item.classId" :key="item.classId">{{
                                    item.className
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12" style="display: block">
                        <a-form-model-item label="姓名" prop="stuName">
                            <a-input v-model="form.stuName" placeholder="姓名" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="性别" prop="sex">
                            <a-radio-group v-model="form.sex" button-style="solid" :disabled="readOnly">
                                <a-radio v-for="dict in sexOptions" :key="dict.dictValue" :value="dict.dictValue">
                                    {{ dict.dictLabel }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="状态" prop="sex">
                            <a-radio-group v-model="form.status" button-style="solid" :disabled="readOnly">
                                <a-radio-button
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :value="dict.dictValue"
                                >
                                    {{ dict.dictLabel }}
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="身份证号码" prop="idNum">
                            <a-input
                                v-model="form.idNum"
                                placeholder="身份证号码"
                                :disabled="readOnly ? true : false"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="学号" prop="stuNum">
                            <a-input
                                v-model="form.stuNum"
                                placeholder="请输入学号"
                                :disabled="readOnly ? true : false"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="出生日期" prop="birthDate">
                            <a-date-picker v-model="form.birthDate" format="YYYY-MM-DD" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="入学日期" prop="entranceTime">
                            <a-date-picker v-model="form.entranceTime" format="YYYY-MM-DD" :disabled="readOnly" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="政治面貌" prop="politicalFace">
                            <a-select v-model="form.politicalFace" :disabled="readOnly">
                                <a-select-option value="13">群众</a-select-option>
                                <a-select-option value="1">中共党员</a-select-option>
                                <a-select-option value="2">中共预备党员</a-select-option>
                                <a-select-option value="3">共青团员</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="家庭住址" prop="address">
                            <a-input
                                v-model="form.address"
                                placeholder="请输入家庭住址"
                                :disabled="readOnly ? true : false"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="联系电话" prop="perPhone">
                            <a-input
                                v-model="form.perPhone"
                                placeholder="请输入联系电话"
                                :disabled="readOnly ? true : false"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="邮箱" prop="email">
                            <a-input
                                v-model="form.email"
                                placeholder="请输入邮箱"
                                :disabled="readOnly ? true : false"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { listUniversity } from '@/api/rongyi/organization/university'
import { listDepartment, getDepartment } from '@/api/rongyi/organization/department'
import { listGrade, getGrade } from '@/api/rongyi/organization/grade'
import { listSpecialize, getSpecialize } from '@/api/rongyi/organization/specialize'
import { listClass, getClass } from '@/api/rongyi/organization/class'
import { addStudent, updateStudent } from '@/api/rongyi/staff/student'
import rryConfig from '@/../config/rongyiYun.config'
import moment from 'moment'
// 表单字段
export default {
    data() {
        return {
            // 状态数据字典
            statusOptions: [],
            // 性别数据字典
            sexOptions: [],
            universityList: [],
            departmentList: [],
            gradeList: [],
            specializeList: [],
            classList: [],
            logoUrl: '',
            token: '',
            temp: {},
            confirmLoading: false,
            readOnly: false,
            spining: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:需要设定默认值的字段必须先定义(Vue的响应式property必须在初始化的时候声明)
            form: {
                universityId: 0,
                departmentId: undefined,
                gradeId: undefined,
                classId: undefined,
                specializeId: undefined,
            },
            // 表单校验
            rules: {
                universityId1: [{ required: true, message: '学校不可为空', trigger: 'blur' }],
                // email: [
                //     {
                //         required: true,
                //         type: 'email',
                //         message: '请输入正确的邮箱地址',
                //         trigger: ['blur', 'change'],
                //     },
                // ],
                linkmanPhone: [
                    {
                        required: true,
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                postcode: [
                    {
                        required: true,
                        pattern: /[1-9][0-9]{5}/,
                        message: '请输入正确的邮编',
                        trigger: 'blur',
                    },
                ],
                address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
                linkman: [{ required: true, message: '联系人不能为空', trigger: 'blur' }],
            },
        }
    },
    created() {
        //初始化字典
        this.getDicts('sys_user_sex').then((response) => {
            this.sexOptions = response.data
        })
        this.getDicts('sys_normal_disable').then((response) => {
            this.statusOptions = response.data
        })

        this.getUniversityList()

        this.getDicts('sys_normal_disable').then((response) => {
            this.statusOptions = response.data
        })
    },
    methods: {
        getUniversityList() {
            const p = new Promise((resolve, reject) => {
                listUniversity().then((response) => {
                    if (response.code == 200) {
                        this.universityList = response.rows
                        resolve(response.rows)
                    } else {
                        reject(response.msg)
                    }
                })
            })
            return p

            listUniversity().then((response) => {
                if (response.code == 200) {
                    this.universityList = response.rows
                }
            })
        },
        getDepartmentList() {
            const p = new Promise((resolve, reject) => {
                if (this.isEmptyStr(this.form.universityId)) {
                    reject('universityId is empty')
                } else {
                    const param = {
                        universityId: this.form.universityId,
                    }
                    let self = this
                    listDepartment(param).then((response) => {
                        if (response.code == 200) {
                            this.departmentList = response.rows
                            resolve(response.rows)
                        } else {
                            reject(response.msg)
                        }
                    })
                }
            })
            return p
        },
        getGradeList() {
            const p = new Promise((resolve, reject) => {
                if (this.isEmptyStr(this.form.universityId)) {
                    reject('universityId is empty')
                } else if (this.isEmptyStr(this.form.departmentId)) {
                    reject('departmentId is empty')
                } else {
                    const param = {
                        universityId: this.form.universityId,
                        departmentId: this.form.departmentId,
                    }
                    listGrade(param).then((response) => {
                        if (response.code == 200) {
                            this.gradeList = response.rows
                            resolve(response)
                        } else {
                            reject(response.msg)
                        }
                    })
                }
            })
            return p
        },
        getSpecializeList() {
            const p = new Promise((resolve, reject) => {
                if (this.isEmptyStr(this.form.universityId)) {
                    reject('universityId is empty')
                } else if (this.isEmptyStr(this.form.departmentId)) {
                    reject('departmentId is empty')
                } else if (this.isEmptyStr(this.form.gradeId)) {
                    reject('gradeId is empty')
                } else {
                    const param = {
                        universityId: this.form.universityId,
                        departmentId: this.form.departmentId,
                        gradeId: this.form.gradeId,
                    }
                    listSpecialize(param).then((response) => {
                        if (response.code == 200) {
                            this.specializeList = response.rows
                            resolve(response)
                        } else {
                            reject(response.msg)
                        }
                    })
                }
            })
            return p
        },
        getClassList() {
            const p = new Promise((resolve, reject) => {
                if (this.isEmptyStr(this.form.universityId)) {
                    reject('universityId is empty')
                } else if (this.isEmptyStr(this.form.departmentId)) {
                    reject('departmentId is empty')
                } else if (this.isEmptyStr(this.form.gradeId)) {
                    reject('gradeId is empty')
                } else if (this.isEmptyStr(this.form.specializeId)) {
                    reject('specializeId is empty')
                } else {
                    const param = {
                        universityId: this.form.universityId,
                        departmentId: this.form.departmentId,
                        gradeId: this.form.gradeId,
                        specializeId: this.form.specializeId,
                    }
                    listClass(param).then((response) => {
                        if (response.code == 200) {
                            this.classList = response.rows
                            resolve(response)
                        } else {
                            reject(response.msg)
                        }
                    })
                }
            })
            return p
        },
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        show(data, readOnly, universityId, departmentId, gradeId, specializeId, classId) {  
            this.visible = true
            this.readOnly = (typeof readOnly !== 'undefined') ? readOnly === true : false
            if (data) {
            console.log("修改行为")                                                      
                this.spining = true;
                // 修改行为
                this.form = Object.assign({}, data) || {}
                //获取学生的分院、年级、专业数据
                getClass(data.classId).then((clz) => {
                    if (clz.code == 200 && clz.data) {
                        this.form.specializeId = clz.data.specializeId
                        getSpecialize(clz.data.specializeId).then((spc) => {
                            if (spc.code == 200 && spc.data) {
                                this.form.gradeId = spc.data.gradeId
                                getGrade(spc.data.gradeId).then((grd) => {
                                    if (grd.code == 200 && grd.data) {
                                        this.form.departmentId = grd.data.departmentId
                                        this.getDepartmentList().then((res) =>
                                            this.getGradeList().then((res) =>
                                                this.getSpecializeList().then((res) =>
                                                    this.getClassList().then((res) => {
                                                        console.log(readOnly)
                                                        this.spining = false;
                                                    })
                                                )
                                            )
                                        )
                                    }else{
                                        this.spining = false;
                                    }
                                })
                            }else{
                                this.spining = false;
                            }
                        })
                    }else{
                        this.spining = false;
                    }
                })
            } else {
                //新增行为
                this.reset()
                // 设置默认值
                this.form.universityId =
                    universityId || this.$store.state.user.hostId || this.rongyiConfig.rongyiUniversityId
                this.form.departmentId = departmentId
                this.form.gradeId = gradeId
                this.form.specializeId = specializeId
                this.form.classId = classId
                console.log('this.form.universityId333')
                console.log(this.form)
            }
            // this.getDepartmentList()

            // this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            console.log('line 455')
            console.log(this.form)
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.reset()
        },
        confirm() {
            console.log('form')
            console.log(this.form)
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('valid success')
                    console.log(this.form)
                    // 进行新增行为:
                    if (!this.isEmptyStr(this.form.stuId)) {
                        // 刷新表格
                        updateStudent(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        console.log('new student form')
                        console.log(this.form)
                        // 新增
                        addStudent(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    console.log('valid false')
                    console.log(this.form)
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        reset() {
            // for(var key in Object.keys(this.form)){
            //     if(key != 'universityId' && key != 'departmentId' && key != 'gradeId' && key != 'specializeId' && key != 'classId')
            //     {
            //         this.form[key] = undefined;
            //     }
            // }
        },
        handleUniversityChange(v) {
            // console.log("handleUniversityChange")
            // console.log(this.form)
            this.getDepartmentList()
        },
        handleDepartmentChange(v) {
            this.getGradeList()
        },
        handleGradeChange(v) {
            this.getSpecializeList()
        },
        handleSpecializeChange(v) {
            this.getClassList()
        },
        handleClassChange(v) {
            this.getClassList()
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
        //操作身份照片上传
        handleChange(info) {
            const self = this
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                console.log(info.file.response.data.name)
                this.form.logo = info.file.response.data.url
                this.loading = false
            }
        },
        beforeUpload(file) {
            console.log('beforeUpload')
            //获取token
            const tk = localStorage.getItem('token')
            if (tk) {
                this.token = 'Bearer ' + tk
            }

            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpeg、png格式的图片文件!')
            }
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('图像大小不能超过 2MB!')
            }
            return isJpgOrPng && isLt2M
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>