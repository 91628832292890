import request from '@/utils/request'

// 查询年级列表
export function listSpecialize (query) {
  return request({
    url: '/rongyi/specialize/list',
    method: 'get',
    params: query
  })
}

// 查询年级详细
export function getSpecialize (specializeId) {
  return request({
    url: '/rongyi/specialize/' + specializeId,
    method: 'get'
  })
}

// 新增年级
export function addSpecialize (data) {
  return request({
    url: '/rongyi/specialize/',
    method: 'post',
    data: data
  })
}

// 修改年级
export function updateSpecialize (data) {
  return request({
    url: '/rongyi/specialize/',
    method: 'put',
    data: data
  })
}

// 删除年级
export function delSpecialize (specializeId) {
  return request({
    url: '/rongyi/specialize/' + specializeId,
    method: 'delete'
  })
}
